import AppRouting from './components/navigation';

function App() {
  return (
    <>
      <AppRouting />
    </>
  );
}

export default App;
