import { Col, Row, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatusDropDown } from "../../../components/Input";
import ShowModal from "../../../components/Modal";
import UpdateUser from "../../../components/Modal/UpdateUser";
import { GetColumnSearchProps } from "../../../components/Table/search";
import styles from "../../../components/Table/table.module.scss";
import {
  asyncGetAllAdmins,
  aysncUpdateUserStatus,
} from "../../../store/Users/UsersAsync";
import {
  addandUpdateAdmin,
  selectAllAdmin,
} from "../../../store/Users/UsersSlice";
import { getFullDate } from "../../pins/active/index";

const AllUsers = () => {
  const allAdmins = useSelector(selectAllAdmin);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncGetAllAdmins());
  }, [dispatch]);
  const changeState = async (id) => {
    // dispatch(asyncGetAllAdmins())
    const res = await dispatch(aysncUpdateUserStatus({ id })).unwrap();
    dispatch(addandUpdateAdmin(res.data));
  };

  // console.log(allAdmins, 'my admins')
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (serial, id, index) => index + 1,
      ...GetColumnSearchProps("id"),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_, { fullName }) => fullName,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...GetColumnSearchProps("email"),
    },
    {
      title: "Phone #",
      dataIndex: "phone",
      key: "phone",
      render: (_, { phone }) => phone ?? "phone",
      ...GetColumnSearchProps("phone"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, { createdAt }) => getFullDate?.(createdAt),
      ...GetColumnSearchProps("date"),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "x",
      width: 80,
      render: (record) => (
        <StatusDropDown
          value1="ACTIVE"
          value2="FREEZE"
          changeState={changeState}
          value={record}
        />
      ),
      filters: [
        {
          text: "visible",
          value: "visible",
        },
        {
          text: "active",
          value: "active",
        },
      ],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 100,
      render: (_, user) => {
        return (
          <div className={styles.action_bttns}>
            <UpdateUser data={user} />
            <ShowModal data={{ role: user.status, id: user._id }} />
          </div>
        );
      },
    },
  ];
  return (
    <Row className={styles.home_row}>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={allAdmins}
          rowKey={(record) => record.id}
        />
      </Col>
    </Row>
  );
};
export default AllUsers;
