import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import logo from "../../assets/icons/logo.svg"
import { CommonButton } from "../../components/Buttons"
import { CustomInput } from "../../components/Input"
import { asyncLogin } from "../../store/Auth/AuthAsync"
import styles from "./login.module.scss"

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const dispatch = useDispatch()
  const onSubmit = (body) => {
    // console.log(body,'body in loginjs')
    dispatch(asyncLogin(body))
  }
  return (
    <div className={styles.login_container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
        <p>logo</p>
      </div>
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            style={{ marginTop: 25 }}
            name="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
            errors={errors}
            lableColor="white"
            lable="Email"
            placeholder="Type Email"
          />
          <CustomInput
            style={{ marginTop: 25 }}
            name="password"
            {...register("password", { required: "Password is required" })}
            errors={errors}
            type="password"
            lableColor="white"
            lable="Password"
            placeholder="Type Password"
          />
          <CommonButton
            htmlType="submit"
            title="Login"
            style={{ width: "100%", marginTop: 30 }}
          />
        </form>
      </div>
    </div>
  )
}
export default Login
