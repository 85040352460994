import { Col, Row, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import envlp from "../../../assets/icons/envlp.svg";
import { StatusDropDown } from "../../../components/Input";
import ShowModal from "../../../components/Modal/index";
import { GetColumnSearchProps } from "../../../components/Table/search";
import styles from "../../../components/Table/table.module.scss";
import {
  asyncGetActiveUsers,
  aysncUpdateUserStatus
} from "../../../store/Users/UsersAsync";
import {
  addFreezeUser,
  selectUserActive,
} from "../../../store/Users/UsersSlice";
import { getFullDate } from "../../pins/active/index";

const expandedRowRender = ({ totalPins }) => {
  return (
    <div className={styles.xpnd_row}>
      <Row className={styles.row}>
        <Col span={10}>
          <div className={styles.rvw_sec}>
            <p className={styles.title}>Address</p>
            <p className={styles.desc}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </Col>
        <Col span={10}>
          <div className={styles.rvw_sec}>
            <p className={styles.title}>Total Pins</p>
            <p className={styles.heighlight}>{totalPins}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ActiveUser = () => {
  const dispatch = useDispatch();
  const AllactiveUsers = useSelector(selectUserActive);
  console.log(AllactiveUsers, "AllactiveUsers");
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(asyncGetActiveUsers());
  }, [dispatch]);
  const handleMessage = (email) => {
    navigate("/emails", { state: { email } });
  };
  const changeState = async (id) => {
    const res = await dispatch(aysncUpdateUserStatus({ id })).unwrap();
    dispatch(addFreezeUser(res.data));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (serial, id, index) => index + 1,
      ...GetColumnSearchProps("id"),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_, { fullName }) => fullName,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...GetColumnSearchProps("email"),
    },
    {
      title: "Phone #",
      dataIndex: "phone",
      key: "phone",
      render: (_, { phone }) => phone ?? "phone",
      ...GetColumnSearchProps("phone"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, { createdAt }) => getFullDate?.(createdAt),
      ...GetColumnSearchProps("date"),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "x",
      width: 80,
      render: (record) => (
        <StatusDropDown
          value1="ACTIVE"
          value2="FREEZE"
          changeState={changeState}
          value={record}
        />
      ),
      filters: [
        {
          text: "visible",
          value: "visible",
        },
        {
          text: "active",
          value: "active",
        },
      ],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 100,
      render: (_, user) => {
        return (
          <div className={styles.action_bttns}>
            <div
              className={styles.bttns}
              onClick={() => handleMessage(user.email)}
            >
              <img alt="envlp" src={envlp} />
            </div>
            <ShowModal
              data={{ status: user.status, id: user._id, role: user.role }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <Row className={styles.home_row}>
      <Col span={24}>
        <Table
          columns={columns}
          expandable={{ expandedRowRender }}
          expandIconColumnIndex={12}
          dataSource={AllactiveUsers}
          rowKey={(record) => record.id}
        />
      </Col>
    </Row>
  );
};
export default ActiveUser;
