import React, { useCallback, useEffect } from "react";
import { Col, Row } from "antd";
import { Table } from "antd";
import styles from "../../../components/Table/table.module.scss";
// import { CommonTable } from "../../../components/Table"
// import tbl from "../../../assets/images/banner/tbl.svg"
import { StatusDropDown } from "../../../components/Input";
import { BsStarFill } from "react-icons/bs";
import { GetColumnSearchProps } from "../../../components/Table/search";
import { useDispatch, useSelector } from "react-redux";
import { asyncChangePinStatus, asyncGetActivePins } from "../../../store/Pins/PinsAsync";
// import { isDraft } from "@reduxjs/toolkit"
import { BASE_URL } from "../../../store/api";
import { addHidePin, selectActivePins } from "../../../store/Pins/PinsSlice";
// import { useState } from "react"
// import { setActivePins } from "../../../store/Pins/PinsSlice"

const expandedRowRender = ({
  viewsCount,
  likes,
  review,
  rating,
  liveStreamLink,
  filesUploaded,
}) => {
  const getImages = filesUploaded?.filter((file) => {
    // let extIndex = file.path.lastIndexOf(".")
    // let ext = v.slice(extIndex).toLowerCase()
    // return ext === ".png" || ext === ".jpg" || ext === ".jpeg"
    return ["image/png", "image/jpg", "image/jpeg"].includes(file.fileType);
  });
  const getVideos = filesUploaded?.filter((file) => {
    // let extIndex = v.lastIndexOf(".");
    // let ext = v.slice(extIndex).toLowerCase();
    // return ext === ".mp4";
    return file.fileType.includes("video/mp4");
  });

  // console.log(getImages, 'dekho')
  return (
    <div className={styles.xpnd_row}>
      <Row className={styles.row}>
        <Col span={10}>
          <div className={styles.pins_div}>
            <div className={styles.header}>
              <div className={styles.text_div}>
                <p className={styles.title}>Views</p>
                <p className={styles.desc}>{viewsCount}</p>
              </div>
              <div className={styles.text_div}>
                <p className={styles.title}>Likes</p>
                <p className={styles.desc}>{likes}</p>
              </div>
            </div>
            <div className={styles.img_sec}>
              <p className={styles.img_title}>
                Images<span>{getImages?.length} / 10</span>
              </p>
              <div className={styles.img_div}>
                {getImages?.length ? (
                  getImages.map((image, i) => {
                    return (
                      <img key={i} src={BASE_URL + image.path} alt="tbl" />
                    );
                  })
                ) : (
                  <p>No images found</p>
                )}
              </div>
              <p className={styles.img_title}>
                Videos<span>{getVideos?.length} / 10</span>
              </p>
              <div className={styles.img_div}>
                {getVideos?.length ? (
                  getVideos.map((file, i) => {
                    // let extIndex = v.lastIndexOf(v);
                    // let ext = v.slice(extIndex).toLowerCase();
                    return (
                      <video key={i} controls width={150} height={150}>
                        <source
                          src={BASE_URL + file.path}
                          type={file.fileType}
                        />
                      </video>
                    );
                  })
                ) : (
                  <p>No videos found</p>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div className={styles.rvw_sec}>
            <div className={styles.header}>
              <p className={styles.title}>Review</p>
              <div className={styles.rvw_div}>
                <p className={styles.text}>{rating}</p>
                <BsStarFill className={styles.icon} />
              </div>
            </div>
            <p className={styles.desc}>{review}</p>
            <div className={styles.link_div}>
              <p>Live Stream Link</p>
              <a href={liveStreamLink}>{liveStreamLink}</a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export const getFullDate = (date) => {
  if (date) {
    return (
      new Date(date).getDate().toLocaleString() +
      "-" +
      (new Date(date).getMonth() + 1) +
      "-" +
      new Date(date).getFullYear()
    );
  }
};

const Active = () => {
  const dispatch = useDispatch();
  const activePins = useSelector(selectActivePins);
  console.log(activePins, "activePins");
  const getActivePins = useCallback(() => {
    dispatch(asyncGetActivePins());
  }, [dispatch]);

  useEffect(() => {
    getActivePins();
  }, [getActivePins]);

  const changeState = async(id) => {
    const res = await dispatch(asyncChangePinStatus({ id })).unwrap();
    console.log(res, "res");  
    if (res.data) dispatch(addHidePin(res.data));
  };

  // console.log(activePins, " cheko");

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (serial, id, index) => index + 1,
      ...GetColumnSearchProps("id"),
    },
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      render: (_, { user }) => user?.fullName ?? "fullName",
      ...GetColumnSearchProps("user"),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, { title }) => title,
      ...GetColumnSearchProps("title"),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, { location }) => location.formattedAddress ?? "location",
      ...GetColumnSearchProps("location"),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_, { createdAt }) => getFullDate?.(createdAt),
      ...GetColumnSearchProps("date"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_, record) => (
        <StatusDropDown
          value1="VISIBLE"
          value2="HIDE"
          changeState={changeState}
          value={record}
        />
      ),
      filters: [
        {
          text: "visible",
          value: "visible",
        },
        {
          text: "hide",
          value: "hide",
        },
      ],
    },
    {
      title: "Types",
      dataIndex: "type",
      key: "type",
      render: (_, { isDraft, isBucket, __t }) =>
        isDraft === false && isBucket === false && __t === "main-pin"
          ? "Pin"
          : isDraft === true
          ? "Draft"
          : isBucket === true
          ? "Bucket"
          : null,
      ...GetColumnSearchProps("type"),
    },
  ];

  return (
    <Row className={styles.home_row}>
      <Col span={24}>
        <Table
          columns={columns}
          expandable={{ expandedRowRender }}
          expandIconColumnIndex={12}
          dataSource={activePins}
          rowKey={(record) => record.id}
        />
      </Col>
    </Row>
  );
};

export default Active;
