import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advertisement: {},
  token: null,
};

export const AdvertisementSlice = createSlice({
  name: "Advertisement",
  initialState,
  reducers: {
    setAllAdvertisement: (state, { payload }) => {
      state.advertisement = payload.reduce(
        (acc, item) => ({ ...acc, [item._id + "advertisement"]: item }),
        {}
      );
    },
    addAdvertisement: (state, { payload }) => {
      state.advertisement = {
        ...state.advertisement,
        [payload._id + "advertisement"]: payload,
      };
    },
    deleteAdvertisement: (state, { payload }) => {
      if (state.advertisement[payload + "advertisement"]) {
        const updatedData = { ...state.advertisement };
        delete updatedData[payload + "advertisement"];
        state.advertisement = updatedData;
      }
    },
  },
});

export const { setAllAdvertisement, addAdvertisement, deleteAdvertisement } =
  AdvertisementSlice.actions;

export default AdvertisementSlice.reducer;

export const allAddvertismentObject = (s) => s.advertisment.advertisement;
