import React from "react"
import { Col, Row } from "antd"
import { CustomInput } from "../../../components/Input"
import { TextDropDown } from "../../../components/TextDD"
import { CommonButton } from "../../../components/Buttons"
import { LayoutCard } from "../../../components/Cards"
import { useForm } from "react-hook-form"
import { asyncCreateAdmin } from "../../../store/Users/UsersAsync"
import { useDispatch } from "react-redux"

const CreateRole = () => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
  })

  const dispatch = useDispatch()

  const onSubmit = async (body) => {
    let res = await dispatch(asyncCreateAdmin({ body })).unwrap()
    if (res) reset()
  }

  return (
    <LayoutCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={44}>
          <Col span={12}>
            <CustomInput
              name="fullName"
              {...register("fullName", { required: "Name is required" })}
              errors={errors}
              lableColor="white"
              lable="Name"
              placeholder="Type Name"
            />
            <CustomInput
              style={{ width: "50%" }}
              name="phone"
              {...register("phone", { required: "Phone is required" })}
              errors={errors}
              lableColor="white"
              lable="Phone"
              placeholder="Type Phone"
            />
            <CustomInput
              style={{ marginTop: 25 }}
              name="password"
              {...register("password", { required: "Password is required" })}
              errors={errors}
              type="password"
              lableColor="white"
              lable="Password"
              placeholder="Type Password"
            />
          </Col>
          <Col span={12}>
            <CustomInput
              name="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              errors={errors}
              lableColor="white"
              lable="Email"
              placeholder="Type Email"
            />
            <TextDropDown
              suffix
              style={{ marginTop: 15 }}
              name="role"
              lable="Select Role"
              setValue={setValue}
              options={[
                { value: "ADMIN", key: "admin" },
                { value: "SUBADMIN", key: "subadmin" },
              ]}
              {...register("role", { required: "Role is required" })}
              errors={errors}
            />
            <CustomInput
              style={{ marginTop: 10 }}
              name="confirmPassword"
              {...register("confirmPassword", {
                required: "Confirm Password is required",
                validate: (value) =>
                  value === getValues().password || "Password does not match",
              })}
              errors={errors}
              type="password"
              lableColor="white"
              lable="Confirm Password"
              placeholder="Type Confirm Password"
            />
          </Col>
        </Row>
        <CommonButton
          htmlType="submit"
          title="Create Role"
          style={{ marginTop: 30, marginRight: "1rem" }}
        />
      </form>
    </LayoutCard>
  )
}
export default CreateRole
