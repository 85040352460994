import React from 'react';

function PrivacyPolicyCom() {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy for Pin+</h1>
      <p style={styles.date}>Effective Date: 1/18/2024</p>
      <h2 style={styles.section}>1. Introduction</h2>
      <p>Welcome to Pin+! This Privacy Policy is designed to inform you about how we collect, use, and protect your personal information when you use our social media application.</p>
      <h2 style={styles.section}>2. Information We Collect</h2>
      <h3 style={styles.subSection}>2.1 User-Provided Information</h3>
      <p>We may collect information that you provide directly to us, such as:</p>
      <ul>
        <li>User profile information (e.g., username, profile picture)</li>
        <li>Content you post, including pins on the map</li>
        <li>Notifications preferences</li>
      </ul>
      <h3 style={styles.subSection}>2.2 Automatically Collected Information</h3>
      <p>We may automatically collect certain information about your device and usage of the app, including:</p>
      <ul>
        <li>Device information (e.g., device type, operating system)</li>
        <li>Usage data (e.g., app interactions, features used)</li>
      </ul>
      <h2 style={styles.section}>3. How We Use Your Information</h2>
      <p>We use the collected information to:</p>
      <ul>
        <li>Enable the core functionality of the app</li>
        <li>Facilitate social interactions and content sharing</li>
        <li>Send notifications based on user-created pins</li>
        <li>Improve and personalize your user experience</li>
      </ul>
      <h2 style={styles.section}>4. Sharing Your Information</h2>
      <p>We do not sell or rent your personal information to third parties. However, by using Pin+, you acknowledge and agree that the content you share, including pins on the map, may be visible to other users.</p>
      <h2 style={styles.section}>5. Your Choices</h2>
      <p>You can manage your privacy settings within the app. You may also choose not to provide certain information, but this may limit your ability to use specific features.</p>
      <h2 style={styles.section}>6. Security</h2>
      <p>While we implement reasonable security measures, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your information.</p>
      <h2 style={styles.section}>7. No Responsibility Disclaimer</h2>
      <p>Pin+ is provided "as is," without warranties of any kind. We do not assume any responsibility or liability for the accuracy, completeness, or reliability of the app or any content shared by users. Your use of Pin+ is at your own risk.</p>
      <h2 style={styles.section}>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy to reflect changes in our practices. We will notify you of any material changes by posting the updated Privacy Policy on our website or within the app.</p>
      <h2 style={styles.section}>9. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at infopinplus@gmail.com</p>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  },
  heading: {
    color: '#007BFF',
    fontSize: '24px',
    marginBottom: '10px',
  },
  date: {
    color: '#666',
    marginBottom: '20px',
  },
  section: {
    color: '#333',
    fontSize: '20px',
    marginBottom: '10px',
  },
  subSection: {
    color: '#555',
    fontSize: '18px',
    marginBottom: '10px',
  },
};

export default PrivacyPolicyCom;
