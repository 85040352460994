import { Select } from "antd";
import React from "react";
import styles from "./input.module.scss";
import classNames from "classnames";

const { Option } = Select;
export const StatusDropDown = ({ value, changeState, value1, value2 }) => {
  // console.log(value.status, 'myvalue')
  // const dispatch = useDispatch()
  // const handleChange = async (status) => {
  //   if (value.role) {
  //     if (value.status !== status) {
  //       await dispatch(aysncUpdateUserStatus({ id: value._id })).unwrap()
  //       changeState?.()
  //     }
  //   } else {
  //     if (value.status !== status) {
  //       await dispatch(asyncChangePinStatus({ id: value._id })).unwrap()
  //       changeState?.()
  //     }
  //   }
  // }
  return (
    <Select
      defaultValue={value.status}
      onChange={() => changeState(value.id)}
      style={{ width: 100 }}
      bordered={false}
    >
      <Option className={styles.green} value={value1.toLowerCase()}>
        {value1}
      </Option>
      <Option className={styles.green} value={value2.toLowerCase()}>
        {value2}
      </Option>
    </Select>
  );
};
export const CommonDropDown = () => {
  return (
    <Select defaultValue="active" style={{ width: 100 }} bordered={false}>
      <Option className={styles.green} value="visible">
        VISIBLE
      </Option>
      <Option className={styles.green} value="active">
        ACTIVE
      </Option>
      <Option className={styles.red} value="hide">
        HIDE
      </Option>
      <Option className={styles.red} value="freez">
        FREEZ
      </Option>
      <Option className={styles.green} value="role">
        ROLE 1
      </Option>
    </Select>
  );
};

export const CommonTextArea = React.forwardRef(
  ({ lable, style, placeholder, rows, cols, ...rest }, ref) => {
    const { errors, name } = rest;
    return (
      <div className={styles.text_area_div} style={style}>
        {lable && <p className={styles.lable}>{lable}</p>}
        <textarea
          style={{ width: "100%", outline: "none" }}
          className={styles.textArea}
          placeholder={placeholder}
          rows={rows}
          cols={cols}
          {...rest}
          ref={ref}
        />
        {errors[name] && <span>{errors[name].message}</span>}
      </div>
    );
  }
);

export const CustomInput = React.forwardRef(
  (
    {
      style,
      fileInputStyle,
      type = "text",
      placeholder = "",
      disabled = false,
      value,
      lableDisabled = false,
      lable,
      lableColor,
      ...rest
    },
    ref
  ) => {
    const { errors, name } = rest;
    return (
      <div className={styles.common_input_main_div} style={style}>
        {lable && (
          <p
            className={styles.common_input_lable}
            style={{ color: lableColor }}
            disabled={lableDisabled}
          >
            {lable}
          </p>
        )}
        <input
          className={styles.common_input_field}
          type={type}
          value={value}
          placeholder={placeholder}
          {...rest}
          ref={ref}
          style={fileInputStyle}
        />
        {errors[name] && (
          <span className={styles.red}>{errors[name].message}</span>
        )}
      </div>
    );
  }
);
export const AdsLevel = ({
  selected,
  changeState,
  lableColor,
  lableDisabled = false,
  lable,
  errors,
  placeholder,
  style,
  name,
  values,
}) => {
  return (
    <div className={styles.common_input_main_div} style={style}>
      {lable && (
        <p
          className={styles.common_input_lable}
          style={{ color: lableColor }}
          disabled={lableDisabled}
        >
          {lable}
        </p>
      )}
      <Select
        defaultValue={selected}
        placeholder={placeholder}
        onChange={(data) => changeState(data)}
        style={{
          width: "100%",
          borderRadius: 5,
          padding: 7,
          backgroundColor: "#eeeeee",
          fontSize: 12,
          verticalAlign: "middle",
        }}
        bordered={false}
      >
        {values?.map((data, index) => {
          return (
            <Option key={index} value={data?.value}>
              {data.label}
            </Option>
          );
        })}
      </Select>
      {errors[name] && (
        <span className={styles.red}>{errors[name].message}</span>
      )}
    </div>
  );
};
