import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

export const pinsSlice = createSlice({
  name: "Pins",
  initialState: {
    draftPins: 0,
    pins: 0,
    recentDraftPins: 0,
    recentPins: 0,
    recentUsers: 0,
    topPins: [],
    topUsers: [],
    users: 0,
    activePins: {},
    hidePins: {},
  },
  reducers: {
    setPins: (state, { payload }) => ({ ...state, ...payload }),
    setActivePins: (state, { payload }) => {
      state.activePins = payload.reduce(
        (acc, pin) => ({ ...acc, [pin.id + "pin"]: pin }),
        {}
      );
    },
    addHidePin: (state, { payload }) => {
        const { id } = payload;
        delete state.activePins[id + "pin"];
        if (state.hidePins[id + "pin"]) {
          state.hidePins[id + "pin"] = payload;
          return;
        }
        state.hidePins = {
          [id + "pin"]: payload,
          ...state.hidePins,
        };
      },
      addActivePin: (state, { payload }) => {
        delete state.hidePins[payload.id + "pin"];
        const { id } = payload;
        if (state.activePins[id + "pin"]) {
          state.activePins[id + "pin"] = payload;
          return;
        }
        state.activePins = {
          [id + "pin"]: payload,
          ...state.activePins,
        };
      },
    setHidePins: (state, { payload }) => {
        state.hidePins = payload?.reduce(
          (acc, pin) => ({ ...acc, [pin.id + "pin"]: pin }),
          {}
        );
      },
  },
});

export const { setPins, setActivePins, setHidePins,addActivePin,addHidePin } = pinsSlice.actions;
export default pinsSlice.reducer;

export const selectActivePins = createDraftSafeSelector([state => state.pins.activePins], (pins) =>
  Object.values(pins)
);
export const selectHidePins = createDraftSafeSelector([state => state.pins.hidePins], (pins) =>
  Object.values(pins)
);