import axios from "axios";

// export const BASE_URL = "https://pinplus.thecodingbuzz.com/"
// export const BASE_URL = "http://srv490143.hstgr.cloud:3001/"
export const BASE_URL = "https://api.ipinplus.com/"
// export const URL = "https://mobile-social-app.herokuapp.com/";
// export const URL = "http://localhost:3000/"
export const IMG_URL = BASE_URL;
export const callApi = ({
  path,
  method = "GET",
  isForm,
  url = null,
  body = null,
  token = null,
}) => {
  let urlString = BASE_URL + path;
  let headers = {
    ...(isForm
      ? {}
      : {
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
  };
  let options = {
    method,
  };
  if (token) headers["Authorization"] = "Bearer " + token;
  options.headers = headers;
  if (body) options.data = body;
  if (url) urlString = url;
  options.url = urlString;
  return axios(options).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return { status: res.status, ...res.data };
  });
};
