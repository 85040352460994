import { Button } from 'antd'
import styles from './button.module.scss'


export const CommonButton = ({
  title,
  style,
  type,
  onClick = () => { },
  ...rest
}) => {
  return (
    <Button type={type} onClick={onClick} className={styles.common_bttn} style={style} {...rest} >
      <p className={styles.common_bttn_p}>
        {title}
      </p>
    </Button>
  )
}
