import React, { useCallback, useEffect } from "react";
import { Col, Row, Table } from "antd";
import styles from "../../../components/Table/table.module.scss";
// import { CommonTable } from "../../../components/Table"
// import tbl from "../../../assets/images/banner/tbl.svg"
import { StatusDropDown } from "../../../components/Input";
import { BsStarFill } from "react-icons/bs";
import { GetColumnSearchProps } from "../../../components/Table/search";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncChangePinStatus,
  asyncGetHidePins,
} from "../../../store/Pins/PinsAsync";
import { getFullDate } from "../active";
import { BASE_URL } from "../../../store/api";
import { addActivePin, selectHidePins } from "../../../store/Pins/PinsSlice";

// const data = [
//     {
//         key: 1,
//         id: '01',
//         name: 'John Brown',
//         title: 'Time Square Plaza',
//         location: 'Time Square, London',
//         date: '15 - JAN - 21',
//         type: 'bucket'
//     },
//     {
//         key: 2,
//         id: '01',
//         name: 'Jim Green',
//         title: 'Time Square Plaza',
//         location: 'Time Square, London',
//         date: '15 - JAN - 21',
//         type: 'bucket'
//     },
//     {
//         key: 3,
//         id: '01',
//         name: 'Not Expandable',
//         title: 'Time Square Plaza',
//         location: 'Time Square, London',
//         date: '15 - JAN - 21',
//         type: 'bucket'
//     },
//     {
//         key: 4,
//         id: '01',
//         name: 'Joe Black',
//         title: 'Time Square Plaza',
//         location: 'Time Square, London',
//         date: '15 - JAN - 21',
//         type: 'bucket'
//     },
// ];
const expandedRowRender = ({
  viewsCount,
  likes,
  review,
  rating,
  liveStreamLink,
  filesUploaded,
}) => {
  // console.log(record, 'props')
  // const getImages = filesUpload?.filter((v, i) => {
  //   let extIndex = v.lastIndexOf(".")
  //   let ext = v.slice(extIndex).toLowerCase()
  //   return ext === ".png" || ext === ".jpg" || ext === ".jpeg"
  // })
  // const getVideos = filesUpload?.filter((v, i) => {
  //   let extIndex = v.lastIndexOf(".")
  //   let ext = v.slice(extIndex).toLowerCase()
  //   return ext === ".mp4"
  // })

  const getImages = filesUploaded?.filter((file) => {
    // let extIndex = file.path.lastIndexOf(".")
    // let ext = v.slice(extIndex).toLowerCase()
    // return ext === ".png" || ext === ".jpg" || ext === ".jpeg"
    return ["image/png", "image/jpg", "image/jpeg"].includes(file.fileType);
  });
  const getVideos = filesUploaded?.filter((file) => {
    // let extIndex = v.lastIndexOf(".");
    // let ext = v.slice(extIndex).toLowerCase();
    // return ext === ".mp4";
    return file.fileType.includes("video/mp4");
  });

  // console.log(getImages)
  return (
    <div className={styles.xpnd_row}>
      <Row className={styles.row}>
        <Col span={10}>
          <div className={styles.pins_div}>
            <div className={styles.header}>
              <div className={styles.text_div}>
                <p className={styles.title}>Views</p>
                <p className={styles.desc}>{viewsCount}</p>
              </div>
              <div className={styles.text_div}>
                <p className={styles.title}>Likes</p>
                <p className={styles.desc}>{likes}</p>
              </div>
            </div>
            <div className={styles.img_sec}>
              <p className={styles.img_title}>
                Images<span>{getImages?.length} / 10</span>
              </p>
              <div className={styles.img_div}>
                {getImages?.length ? (
                  getImages.map((image, i) => {
                    return (
                      <img key={i} src={BASE_URL + image.path} alt="tbl" />
                    );
                  })
                ) : (
                  <p>No images found</p>
                )}
              </div>
              <p className={styles.img_title}>
                Videos<span>{getVideos?.length} / 10</span>
              </p>
              <div className={styles.img_div}>
                {getVideos?.length ? (
                  getVideos.map((video, i) => {
                    // let extIndex = v.lastIndexOf(v)
                    // let ext = v.slice(extIndex).toLowerCase()
                    return (
                      <video key={i} controls width={250} height={250}>
                        <source
                          src={BASE_URL + video?.path}
                          type={video?.fileType}
                        />
                      </video>
                    );
                  })
                ) : (
                  <p>No videos found</p>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div className={styles.rvw_sec}>
            <div className={styles.header}>
              <p className={styles.title}>Review</p>
              <div className={styles.rvw_div}>
                <p className={styles.text}>{rating}</p>
                <BsStarFill className={styles.icon} />
              </div>
            </div>
            <p className={styles.desc}>{review}</p>
            <div className={styles.link_div}>
              <p>Live Stream Link</p>
              <a href={liveStreamLink}>{liveStreamLink}</a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const HidePins = () => {
  const dispatch = useDispatch();
  const hidePins = useSelector(selectHidePins);

  const getHidePins = useCallback(() => {
    dispatch(asyncGetHidePins());
  }, [dispatch]);

  useEffect(() => {
    getHidePins();
  }, [getHidePins]);

  const changeState = async(id) => {
    const res = await dispatch(asyncChangePinStatus({ id })).unwrap();
    if (res.data) dispatch(addActivePin(res.data));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (serial, id, index) => index + 1,
      ...GetColumnSearchProps("id"),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_, { user }) => user?.fullName ?? "fullName",
      ...GetColumnSearchProps("name"),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, { title }) => title,
      ...GetColumnSearchProps("title"),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, { location }) => location.formattedAddress ?? "location",
      ...GetColumnSearchProps("location"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, { createdAt }) => getFullDate?.(createdAt),
      ...GetColumnSearchProps("date"),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "x",
      width: 100,
      render: (_, record) => (
        <StatusDropDown
          value1="VISIBLE"
          value2="HIDE"
          changeState={changeState}
          value={record}
        />
      ),
      filters: [
        {
          text: "visible",
          value: "visible",
        },
        {
          text: "hide",
          value: "hide",
        },
      ],
    },
    {
      title: "Types",
      dataIndex: "type",
      key: "type",
      ...GetColumnSearchProps("type"),
    },
  ];

  return (
    <Row className={styles.home_row}>
      <Col span={24}>
        <Table
          columns={columns}
          expandable={{ expandedRowRender }}
          expandIconColumnIndex={12}
          dataSource={hidePins}
          rowKey={(record) => record.id}
        />
      </Col>
    </Row>
  );
};
export default HidePins;
