import React from "react"
import { Col, Row } from "antd"
import styles from "./home.module.scss"
import { CommonTable } from "../../components/Table"
import { useSelector } from "react-redux"
import { BASE_URL } from "../../store/api"

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 50,
  },
  {
    title: "User Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Views",
    dataIndex: "views",
    key: "views",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
]

const Home = () => {
  const { topPins, topUsers } = useSelector((s) => s.pins)
  let key = 1
  const data =
    topPins &&
    topPins.map((v, i) => {
      let arr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
      let { user, title, rating, createdAt } = v
      let mydate = new Date(createdAt)
      let getDay = mydate.getDate()
      let getMonth = mydate.getMonth()
      let getYear = mydate.getFullYear()
      let combineTheDate = `${getDay}-${arr[getMonth]}-${getYear}`
      return {
        key: i,
        id: key++,
        name: user?.fullName,
        title: title,
        views: rating,
        date: combineTheDate,
      }
    })
  const userData =
    topUsers &&
    topUsers.map((v, i) => {
      let { uploadImage, fullName, pins } = v
      return {
        img: BASE_URL + uploadImage,
        title: fullName,
        value: pins === 0 ? `${pins}` : `${pins}+`,
      }
    })
  // console.log('mydata', data)
  return (
    <Row gutter={[24, 24]} className={styles.home_row}>
      <Col span={18}>
        <CommonTable isPagination={false} data={data} columns={columns} />
      </Col>
      <Col span={6}>
        <div className={styles.top_users}>
          <div className={styles.header}>
            <p>top users</p>
          </div>
          <div className={styles.content}>
            <ul>
              {userData?.map((userData) => (
                <li>
                  <div className={styles.user_list}>
                    <div className={styles.img_div}>
                      <img alt="userImg" src={userData.img} />
                    </div>
                    <p>{userData.title}</p>
                  </div>
                  <p>{userData.value}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
    </Row>
  )
}
export default Home
