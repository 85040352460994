import { Col, Row } from "antd";
import { CommonButton } from "../../components/Buttons";
import { AdsLevel, CustomInput } from "../../components/Input";
import { Controller, useForm } from "react-hook-form";
import { LayoutCard } from "../../components/Cards";
import { useDispatch } from "react-redux";
import { createAdvertisment } from "../../store/Advertisement/AdvertismentAsync";
import { useState } from "react";
import { asyncShowError } from "../../store/Users/UsersSlice";

const KEY = [
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "mid",
  },
  {
    label: "Low",
    value: "low",
  },
];

const AddAdvertisement = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      adsLevel: "",
      // uploadFile: "",
    },
  });
  const dispatch = useDispatch();
  console.log("errors", errors);
  const onSubmit = async (body) => {
    if (body) {
      const formData = new FormData();
      formData.append("uploadFile", body.uploadFile);
      formData.append("title", body.title);
      // formData.append("adsLevel", body.adsLevel);
      console.log("formData", body);
      const res = await dispatch(createAdvertisment(formData)).unwrap();
      if (res.success) {
        reset();
      }
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size;
      const allowedImageTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/webp",
      ];
      const allowedVideoTypes = ["video/mp4"];
      const maxImageSize = 400 * 1024;
      const maxVideoSize = 4 * 1024 * 1024;
      const maxVideoDuration = 15;

      if (allowedImageTypes.includes(fileType)) {
        if (fileSize <= maxImageSize) {
          return selectedFile;
        } else {
          dispatch(
            asyncShowError({
              message: "Invalid file size",
              description: "Image size should be less than 400kb.",
            })
          );
          event.target.value = null;
          return null;
        }
      } else if (allowedVideoTypes.includes(fileType)) {
        if (
          fileSize <= maxVideoSize &&
          selectedFile.duration <= maxVideoDuration
        ) {
          return selectedFile;
        } else {
          dispatch(
            asyncShowError({
              message: "Invalid file size or duration",
              description:
                "Video size should be less than 4mb and duration should be less than 15 seconds.",
            })
          );
          event.target.value = null;

          return null;
        }
      } else {
        dispatch(
          asyncShowError({
            message: "Invalid file type",
            description:
              "Please select a valid file type. Allowed types: PNG, JPEG, JPG, WebP, MP4",
          })
        );
        event.target.value = null;

        return null;
      }
    }
    event.target.value = null;
    return null;
  };
  return (
    <LayoutCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[44, 24]}>
          <Col span={12}>
            <CustomInput
              style={{ marginTop: 25 }}
              name="title"
              {...register("title", { required: "title is required" })}
              errors={errors}
              type="text"
              lableColor="black"
              lable="Enter title"
              placeholder="Type title"
            />
          </Col>
          {/* <Col span={12}>
            <Controller
              control={control}
              name="adsLevel"
              rules={{ required: "adsLevel is required" }}
              render={({ field: { onChange, value } }) => (
                <AdsLevel
                  changeState={onChange}
                  values={KEY}
                  lableColor="black"
                  placeholder="Select Level"
                  errors={errors}
                  name="adsLevel"
                  style={{ marginTop: 25 }}
                  selected={value}
                  lable="Select Level"
                />
              )}
            />
          </Col> */}
          <Col span={12}>
            <Controller
              control={control}
              name="uploadFile"
              rules={{ required: "uploadFile is required" }}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  style={{ marginTop: 25 }}
                  // value={value}
                  onChange={(e) => {
                    onChange(handleFileChange(e));
                  }}
                  fileInputStyle={{
                    height: "auto",
                    paddingTop: 11,
                    paddingBottom: 15,
                  }}
                  name="uploadFile"
                  type="file"
                  lableColor="black"
                  errors={errors}
                  lable="upload File"
                  placeholder="Type uploadFile"
                />
              )}
            />
          </Col>
        </Row>
        <CommonButton
          htmlType="submit"
          title="Submit"
          style={{ marginTop: 20 }}
        />
      </form>
    </LayoutCard>
  );
};
export default AddAdvertisement;
