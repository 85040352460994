import { createAsyncThunk } from "@reduxjs/toolkit"
import { callApi } from "../api"
import { setActivePins, setPins, setHidePins } from "./PinsSlice"

export const asyncGetDashbaord = createAsyncThunk(
  "GetDashbaord",
  async ({ type }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `${type}/dashboard`,
        token: getState().login.token,
      })
      // console.log(res)
      if (res.success) {
        dispatch(setPins(res.data))
        return res
      }
      if (res.success === false) {
        return res
      }
      return { success: true, d: "data" }
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
)

export const asyncGetActivePins = createAsyncThunk(
  "GetActivePins",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/active-pins`,
        token: getState().login.token,
      })
      // console.log(res)
      if (res.success) {
        dispatch(setActivePins(res.data))
        return res
      }

      return res
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
)
export const asyncGetHidePins = createAsyncThunk(
  "GetHidePins",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/hide-pins`,
        token: getState().login.token,
      })
      // console.log(res)
      if (res.success) {
        dispatch(setHidePins(res.data))
        return res
      }

      return res
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
)
export const asyncChangePinStatus = createAsyncThunk(
  "GetHidePins",
  async ({ id }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/pins/${id}`,
        method: "PUT",
        token: getState().login.token,
      })

      // console.log(res)
      if (res.success) {
        return res
      }
      if (res.success === false) {
        return res
      }
      return res
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
)
