import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAdvertisement,
  deleteAdvertisement,
  setAllAdvertisement,
} from "./AdvertismentSlice";
import { callApi } from "../api";
import { asyncShowError, asyncShowSuccess } from "../Users/UsersSlice";

export const getAllAdvertisments = createAsyncThunk(
  "gellAllAdvertisments",
  async (body, { dispatch, getState }) => {
    try {
      const res = await callApi({
        path: `superadmin/ads`,
        method: "GET",
        token: getState().login.token,
      });
      if (res.success) {
        dispatch(setAllAdvertisement(res.data));
        asyncShowSuccess({
          message: "Get All Advertisements",
          description: res.message,
        });
        return res;
      } else if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Get All Advertisements",
            description: res.message,
          })
        );
        return res;
      }
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Get All Advertisements",
          description: error.message,
        })
      );
      return { success: false };
    }
  }
);

export const createAdvertisment = createAsyncThunk(
  "createAdvertisment",
  async (body, { dispatch, getState }) => {
    try {
      const res = await callApi({
        path: `superadmin/ads`,
        method: "POST",
        token: getState().login.token,
        body,
      });
      console.log(res, "res");
      if (res.success) {
        dispatch(addAdvertisement(res.data));
        dispatch(
          asyncShowSuccess({
            message: "Create Advertisements",
            description: res.message,
          })
        );
        return res;
      } else if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Create Advertisements",
            description: res.message,
          })
        );
        return res;
      }
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Create Advertisements",
          description: error.message,
        })
      );
      return { success: false };
    }
  }
);
export const deleteAdvertisment = createAsyncThunk(
  "createAdvertisment",
  async (id, { dispatch, getState }) => {
    try {
      const res = await callApi({
        path: `superadmin/ads/${id}`,
        method: "DELETE",
        token: getState().login.token,
        body: {
          id,
        },
      });
      console.log(res, "res");
      if (res.success) {
        dispatch(deleteAdvertisement(id));
        asyncShowSuccess({
          message: "delete Advertisements",
          description: res?.message || "Advertisement deleted successfully",
        });
        return res;
      } else if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "delete Advertisements",
            description: res.message || "Advertisement not deleted",
          })
        );
        return res;
      }
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "delete Advertisements",
          description: error.message || "something went wrong",
        })
      );
      return { success: false };
    }
  }
);
